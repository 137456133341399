<template>
    <div class="content">
      <div class="inner-content">
        <div class="home-slider">
          <IntroCard :image="imageUrlFor(welcomeImage)" :text="welcomeText" />
        </div>
        <div class="pagination-control">
          <router-link
            :to="{ name: 'Training', params: { slug: $route.params.slug } }"
            class="btn-primary next-slide"
            >Start</router-link
          >
        </div>
      </div>
    </div>
</template>

<script>
import IntroCard from "@/components/cards/introCard";
import sanity from "../sanity";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);
const query = `*[_type == "training" && slug.current == $slug && enable == true]`;

export default {
  name: "Home",
  components: {
    IntroCard,
  },
  data() {
    return {
      selectedSlide: 1,
      slide: null,
      cards: null,
      totalSlides: null,
      welcomeImage: null,
      welcomeText: null
    };
  },
  created() {
    localStorage.setItem("currentSlide", 1);

    sanity
      .fetch(query, { slug: this.$route.params.slug })
      .then((response) => {
        let data = response[0]
        this.welcomeImage = data.welcome_image
          ? data.welcome_image.asset._ref
          : null;
        this.welcomeText = data.welcome_text;
      })
      .catch(() => this.$router.push({ name: "PageNotFound" }));
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
  },
};
</script>