<template>
  <div>
    <Menu
      :selectedSlide="selectedSlide"
      :slides="totalSlides"
      :cardTitle="cardTitle"
      :instructions="instructions"
    />
    <div class="content">
      <div class="inner-content">
        <div>
          <div class="slide" v-for="(card, index) in cards" :key="index">
            <div class="inner-slide" v-show="selectedSlide === index + 1">
              <FacebookCard
                v-if="card.type === 'facebook'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :thumbs_up="card.thumbs_up"
                :comments="card.comments"
                :share="card.share"
                :profileName="card.profile_name"
              />
              <YoutubeCard
                v-if="card.type === 'youtube'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :video="card.url"
              />
              <InstagramCard
                v-if="card.type === 'instagram'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :thumbs_up="card.thumbs_up"
                :comments="card.comments"
                :share="card.share"
                :profileName="card.profile_name"
              />
              <TwitterCard
                v-if="card.type === 'twitter'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
                :thumbs_up="card.thumbs_up"
                :comments="card.comments"
                :share="card.share"
                :profileName="card.profile_name"
              />
              <FactCheckCard
                v-if="card.type === 'fact-check'"
                :factIntro="card.fact_check_intro"
                :factTruth="card.fact_check_truth"
                :factClaim="card.fact_check_claim"
                :glossaryTerms="card.glossary_terms"
              />
              <ImageCard
                v-if="card.type === 'image'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
              />
              <TextCard
                v-if="card.type === 'text'"
                :image="card.image ? imageUrlFor(card.image.asset._ref) : null"
                :text="card.content"
                :glossaryTerms="card.glossary_terms"
              />
              <AnswerForm v-if="card.answers" :cardId="card._id" :sanityDocument="sanityDocument" />
            </div>
          </div>
        </div>
        <div class="pagination-control">
          <a
            class="btn-primary prev-slide"
            v-if="selectedSlide != 1"
            @click="prevSlide()"
          >
            Prev slide
          </a>
          <a
            class="btn-primary next-slide"
            v-if="selectedSlide != slide"
            @click="nextSlide()"
          >
            Next slide
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookCard from "@/components/cards/facebookCard";
import YoutubeCard from "@/components/cards/youtubeCard";
import InstagramCard from "@/components/cards/instagramCard";
import TwitterCard from "@/components/cards/twitterCard";
import FactCheckCard from "@/components/cards/factCheckCard";
import ImageCard from "@/components/cards/imageCard";
import TextCard from "@/components/cards/textCard";
import Menu from "@/components/Menu.vue";
import AnswerForm from "@/components/AnswerForm.vue";
import sanity from "../sanity";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);
const query = `*[_type == "training" && slug.current == $slug && enable == true]`;
const cardQuery = `*[_type == "training" && slug.current == $slug && enable == true] {
  "relatedCards": cards[]->{_id, answers, url, profile_name, fact_check_intro, fact_check_claim, fact_check_truth, type, title, image, content, thumbs_up, comments, share, introduction, glossary_terms[]->{term_name, term_text}}
}`;

export default {
  name: "Training",
  components: {
    FacebookCard,
    YoutubeCard,
    InstagramCard,
    TwitterCard,
    FactCheckCard,
    ImageCard,
    TextCard,
    Menu,
    AnswerForm
  },
  data() {
    return {
      selectedSlide: 1,
      slide: null,
      cards: null,
      totalSlides: null,
      interval: null,
      cardTitle: null,
      instructions: null,
      sanityDocument: null,
      currentSlide: localStorage.getItem("currentSlide")
        ? parseInt(localStorage.getItem("currentSlide"))
        : 1,
    };
  },
  created() {
    sanity
      .fetch(query, { slug: this.$route.params.slug })
      .then((response) => {
        let data = response[0]
        this.sanityDocument = data._id;
        this.slide = data.training_slide;

        if (!localStorage.getItem("currentSlide")) {
          this.selectedSlide = 1;
        } else {
          this.selectedSlide = parseInt(localStorage.getItem("currentSlide"));
          if (this.selectedSlide > this.slide) {
            this.selectedSlide = 1;
            localStorage.setItem("currentSlide", 1);
          }
        }
      })
      .catch(() => this.$router.push({ name: "PageNotFound" }));

    sanity
      .fetch(cardQuery, { slug: this.$route.params.slug })
      .then((response) => {
        let data = response[0]
        this.cards = data.relatedCards;
        this.totalSlides = this.cards.length;
        this.cardTitle = this.cards[0].title;
        this.instructions = this.cards[0].introduction;
      });
  },
  mounted() {
    this.interval = setInterval(() => this.getNewSlide(), 10000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    async getNewSlide() {
      sanity
        .fetch(query, { slug: this.$route.params.slug })
        .then((response) => {
          this.slide = response[0].training_slide;
          if (this.slide === this.totalSlides) {
            clearInterval(this.interval);
          }
        });
    },
    imageUrlFor(source) {
      return imageBuilder.image(source)
    },
    clearData() {
      this.image = null
    },
    nextSlide() {
      this.currentSlide += 1
      this.selectedSlide += 1
      localStorage.setItem("currentSlide", parseInt(this.currentSlide))
      this.clearData()
      window.scrollTo(0, 0)
      this.setTitleAndIntro()
    },
    prevSlide() {
      this.currentSlide -= 1
      this.selectedSlide -= 1
      localStorage.setItem("currentSlide", parseInt(this.currentSlide))
      this.clearData()
      window.scrollTo(0, 0)
      this.setTitleAndIntro()
    },
    setTitleAndIntro() {
      this.cardTitle = this.cards[this.selectedSlide - 1].title
      this.instructions = this.cards[this.selectedSlide - 1].introduction
    },
  },
};
</script>
