<template>
  <div id="app">
    <div class="container">
      <Menu v-if="$route.name != 'Training'"/>
      <router-view />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import sanity from "./sanity";

const query = `*[_type == "training" && slug.current == $slug && enable == true]`;

export default {
  name: "App",
  components: {
    Menu,
  },
  created() {
    sanity
      .fetch(query, { slug: this.$route.params.slug })
      .then((response) => {
        let data = response[0];
        this.welcomeText = data.welcome_text;
        this.isHomeIntroMsg = data.introduction;
      })
      .catch(() => this.$router.push({ name: "PageNotFound" }));
  }
};
</script>

<style lang="scss">
@import "./assets/scss/reset.scss";
@import "./assets/scss/common.scss";
@import "./assets/scss/cards.scss";
</style>
