<template>
    <div id="glossary-term" class="content">
      <div class="inner-content">
        <h3 class="border-bt-primary">{{ termName }}</h3>
        <p>
          {{ termText }}
        </p>
        <button class="btn-primary" @click="$router.back()">Back</button>
      </div>
    </div>
</template>

<script>
import sanity from "../sanity";

const query = `*[_type == "glossary" && term_name == $term]`;

export default {
  name: "GlossaryTermComponent",
  data() {
    return {
      termText: null,
      termName: null,
    };
  },
  created() {
    this.getContent(this.$route.params.term);
  },
  methods: {
    getContent(param) {
      sanity.fetch(query, { term: param }).then((response) => {
        let data = response[0]
        this.termText = data.term_text;
        this.termName = data.term_name;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-primary{
    margin-top: 52px;
    margin-bottom: 52px;
}

p{
  white-space: break-spaces;
}
</style>