<template>
  <div class="image-card">
    <img v-if="image" :src="image" alt="" />
    <div class="caption"><CardContentHighlight :text="text" :terms="glossaryTerms" /></div>
  </div>
</template>

<script>
import CardContentHighlight from "@/components/CardContentHighlight";

export default {
  name: "ImageCard",
  props: {
    image: {
      type: Object,
    },
    text: {},
    glossaryTerms: {
      type: Array
    },
  },
  components: {
    CardContentHighlight,
  },
};
</script>

<style lang="scss" scoped>
img{
  width: 100%;
}
</style>
