<template>
  <div class="video-card">
    <div class="image">
      <img v-if="image && !showingVideo" :src="image" alt="" />
      <img
        src="@/assets/images/youtube-icon.svg"
        alt="youtube-play-icon"
        class="youtube-play"
        @click="showVideo()"
        v-if="!showingVideo"
      />
    </div>
    <iframe height="300px" v-if="showingVideo" :src="'//www.youtube.com/embed/' + getId(this.video)" frameborder="0"></iframe>
    <div class="caption">
      <CardContentHighlight :text="text" :terms="glossaryTerms" />
    </div>
  </div>
</template>

<script>
import CardContentHighlight from "@/components/CardContentHighlight";

export default {
  name: "YoutubeCard",
  props: {
    image: {
      type: Object,
    },
    text: {
      type: Array,
    },
    glossaryTerms: {
      type: Array,
    },
    video: {
      type: String,
    },
  },
  methods: {
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    showVideo(){
      this.showingVideo = !this.showingVideo
    }
  },
  data(){
    return{
      showingVideo: false
    }
  },
  components: {
    CardContentHighlight,
  },
};
</script>

<style lang="scss" scoped>
iframe{
  width: 100%;
}
</style>
