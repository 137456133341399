<template>
  <aside id="menu">
    <div class="menu-content">
      <div class="inner-menu-content">
        <div class="pagination">
          <img src="@/assets/images/logo.png" alt="" class="logo hidden-xs" />
          <router-link
            :to="{
              name: 'Training',
              params: { slug: $route.params.slug },
            }"
          >
            <img class="visible-xs" src="@/assets/images/logo.png" alt="" />
            <img class="hidden-xs" src="@/assets/images/home-icon.svg" alt=""
          /></router-link>
          <span v-if="$route.name == 'Training'"
            >{{ selectedSlide }} of {{ slides }}</span
          >
        </div>
      </div>
      <div class="intro" v-if="$route.name == 'Training'">
        <h1>{{ cardTitle }}</h1>
        <p>{{ instructions }}</p>
      </div>
      <div class="intro" v-if="$route.name == 'Answers'">
        <h1>Answers from the teams</h1>
      </div>
      <div
        class="intro"
        v-if="$route.name == 'GlossaryIndex' || $route.name == 'GlossaryTerm'"
      >
        <h1>Glossary</h1>
      </div>
      <div class="intro" v-if="$route.name == 'Home'">
        <h1>{{ isHomeIntroMsg }}</h1>
        <p>{{ trainingInfo }}</p>
      </div>
      <div class="inner-menu-content">
        <div class="border bg-gray"></div>
        <div class="glossary">
          <img src="@/assets/images/arrow-icon.svg" alt="" />
          <router-link
            :to="{
              name: 'GlossaryIndex',
              params: { slug: $route.params.slug },
            }"
            >Glossary</router-link
          >
        </div>
        <div class="glossary">
          <img src="@/assets/images/arrow-icon.svg" alt="" />
          <router-link
            :to="{ name: 'Answers', params: { slug: $route.params.slug } }"
            >Answers</router-link
          >
        </div>
        <div class="border bg-gray hidden-xs"></div>
        <img src="@/assets/images/logo.png" alt="" class="logo hidden-xs" />
      </div>
    </div>
  </aside>
</template>

<script>
import sanity from "../sanity";

const query = `*[_type == "training" && slug.current == $slug && enable == true]`;

export default {
  name: "Menu",
  props: {
    selectedSlide: {
      type: Number,
    },
    slides: {
      type: Number,
    },
    cardTitle: {
      type: String,
    },
    instructions: {
      type: String,
    },
  },
  data() {
    return {
      isHomeIntroMsg: null,
      trainingInfo: null,
    };
  },
  created() {
    sanity.fetch(query, { slug: this.$route.params.slug }).then((response) => {
      let data = response[0];
      this.isHomeIntroMsg = data.introduction;
      this.trainingInfo = data.training_info;
    });
  },
};
</script>

<style lang="scss" scoped>
#menu {
  width: $menu-width;
  height: 930px;
  position: fixed;
  background: $color-black;
  padding: 64px 0px 64px 64px;
  @media (max-width: $breakpoint-mobile) {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0 24px 24px 24px;
    border-top: 4px solid $color-primary;
  }
  .menu-content {
    position: relative;
    height: 100%;
  }
  .inner-menu-content {
    padding-right: 64px;
    @media (max-width: $breakpoint-mobile) {
      padding-right: 0;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  margin-bottom: $space-big;
  padding-top: 34.5px;
  border-top: 4px solid $color-primary;
  @media (max-width: $breakpoint-mobile) {
    border: none;
  }
  img {
    margin-right: $space-small;
  }
  span {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
  }
}

.intro {
  margin-bottom: $space-big;
  background: $color-bg;
  padding: 24px;
  @media (max-width: $breakpoint-mobile) {
    margin-bottom: $space-small;
    background: $color-black;
    padding: 0;
  }
  p {
    color: $color-black;
    @media (max-width: $breakpoint-mobile) {
      color: $color-white;
    }
  }
}

.glossary {
  margin-bottom: 24px;
  margin-top: 24px;
  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  img {
    margin-right: 12px;
  }
  a {
    color: $color-white;
  }
}

.logo {
  position: absolute;
  bottom: 0;
}
</style>
