<template>
  <div class="image-card">
    <img v-if="image" :src="image" alt="" />
    <div class="caption">{{text}}</div>
  </div>
</template>

<script>

export default {
  name: "IntroCard",
  props: {
    image: {
      type: Object,
    },
    text: {
      type: String,
    }
  }
};
</script>

<style lang="scss" scoped>
img{
  width: 100%;
}
</style>
