<template>
  <div class="fact-check">
    <CardContentHighlight :text="factIntro" :terms="glossaryTerms" />
    <h3>Claim</h3>
    <CardContentHighlight :text="factTruth" :terms="glossaryTerms" />
    <h3>Truth</h3>
    <CardContentHighlight :text="factClaim" :terms="glossaryTerms" />
  </div>
</template>

<script>
import CardContentHighlight from "@/components/CardContentHighlight";

export default {
  name: "FactCheckCard",
  components: {
    CardContentHighlight,
  },
  props: {
    factIntro: {
      type: String,
    },
    factClaim: {
      type: String,
    },
    factTruth: {
      type: String,
    },
    glossaryTerms: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.fact-check {
  border: 4px solid $color-primary;
  padding: 24px;
  margin-bottom: 100px;
  h3 {
    font-family: $font-oswald;
    text-transform: uppercase;
    margin-bottom: 16px;
    margin-top: 32px;
  }
}
</style>
