import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Training from '../views/Training.vue'
import GlossaryIndex from '../views/GlossaryIndex.vue'
import GlossaryTerm from '../views/GlossaryTerm.vue'
import Answers from '../views/Answers.vue'
import PageNotFound from '../views/PageNotFound.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/:slug',
    name: 'Home',
    component: Home
  },
  {
    path: '/training/:slug',
    name: 'Training',
    component: Training
  },
  {
    path: '/glossary/:slug',
    name: 'GlossaryIndex',
    component: GlossaryIndex
  },
  {
    path: '/glossary-term/:slug/:term',
    name: 'GlossaryTerm',
    component: GlossaryTerm
  },
  {
    path: '/answers/:slug',
    name: 'Answers',
    component: Answers
  },
  { name: 'PageNotFound', path: "*", component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
