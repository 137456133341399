<template>
  <div class="card form-card" :class="{ 'submitted': submitted }">
    <form action="" @submit="checkForm($event, cardId)" v-if="!submitted">
      <label for="team_name">Team name</label>
      <input type="text" v-model="teamName" :class="{ 'error': errors }" />
      <label for="message">Message</label>
      <textarea
        :class="{ 'error': errors }"
        id="message"
        name="message"
        v-model="message"
      ></textarea>
      <label for="file">Upload image</label>
      <div class="file-uploader">
        <div class="btn" @click="chooseFile()">Choose File</div>
        <span v-if="!fileName">No file choosen</span>
        <span v-if="fileName">{{ fileName }}</span>
      </div>
      <input type="file" id="file" ref="fileInput" @change="changeFileName()" />
      <input type="submit" value="Send answers" />
    </form>
    <span v-if="submitted" class="submitted-answers"
      >Your answers are submitted!</span
    >
  </div>
</template>

<script>
import sanity from "../sanity";

export default {
  name: "Training",
  props: {
    cardId: {
      type: String,
    },
    sanityDocument: {
      type: String,
    },
  },
  data() {
    return {
      message: null,
      teamName: null,
      fileName: null,
      errors: false,
      submitted: null,
    };
  },
  mounted(){

  },
  methods: {
    changeFileName() {
      this.fileName = this.$refs.fileInput.value.match(/[^\\/]*$/)[0];
    },
    chooseFile() {
      let fileInput = document.getElementById("file");
      fileInput.click();
    },
    checkForm(e, id) {
      e.preventDefault();

      if (this.message && this.teamName) {
        this.errors = false;

        // If answers contain image, save it
        if (this.$refs.fileInput != null) {
          let image = this.$refs.fileInput.files[0];

          sanity.assets
            .upload("image", image, {
              filename: image,
            })
            .then((imageAsset) => {
              sanity.create({
                _type: "answers",
                training: {
                  _type: "reference",
                  _ref: this.sanityDocument,
                },
                answers_to_card: {
                  _type: "reference",
                  _ref: id,
                },
                image: {
                  _type: "image",
                  asset: {
                    _type: "reference",
                    _ref: imageAsset._id,
                  },
                },
                team_name: this.teamName,
                team_answer: this.message,
              });
            });
        } else {
          // Answer doesn't include image
          sanity.create({
            _type: "answers",
            training: {
              _type: "reference",
              _ref: this.sanityDocument,
            },
            answers_to_card: {
              _type: "reference",
              _ref: id,
            },
            team_name: this.teamName,
            team_answer: this.message,
          });
        }
        // Show success message
        this.submitted = true;
      } else {
        this.errors = true;
      }
    },
    clearData() {
      this.filename = null;
      this.errors = false;
      this.image = null;
      this.teamName = null;
      this.message = null;
      this.submitted = null;
    },
  },
};
</script>
