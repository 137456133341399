<template>
    <div id="glossary-index" class="content">
      <div class="inner-content">
        <div
          class="glossary-term"
          v-for="(item, index) in glossary"
          :key="index"
        >
          <h3 class="border-bt-primary">
            <router-link
              :to="{
                name: 'GlossaryTerm',
                params: { slug: $route.params.slug, term: item.term_name },
              }"
              >{{ item.term_name }}</router-link
            >
          </h3>
          <p>
            {{ shortenString(item.term_text, 220) }}
            <router-link
              :to="{
                name: 'GlossaryTerm',
                params: { slug: $route.params.slug, term: item.term_name },
              }"
              class="read-more"
              >Read more</router-link
            >
          </p>
        </div>
      </div>
    </div>
</template>

<script>
import sanity from "../sanity";

const query = `*[_type == "training" && slug.current == $slug && enable == true] {
    "relatedCards": [cards[]->{glossary_terms[]->{term_name, term_text}}]
}`;

export default {
  name: "GlossaryIndex",
  data() {
    return {
      glossary: [],
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      sanity
        .fetch(query, { slug: this.$route.params.slug })
        .then((response) => {
          response[0].relatedCards.forEach((element) => {
            element.forEach((subEl) => {
              if (subEl.glossary_terms != null) {
                subEl.glossary_terms.forEach((terms) => {
                  if (!this.glossary.some((e) => e.term_name === terms.term_name)) {
                    this.glossary.push(terms);
                  }
                });
              }
            });
          });
        });
    },
    shortenString(source_string, max_length) {
      var short = source_string.substr(0, max_length);
      if (/^\S/.test(source_string.substr(max_length)))
        return short.replace(/\s+\S*$/, "") + "...";
      return short;
    },
  }
};
</script>

<style lang="scss" scoped>
.glossary-term {
  margin-bottom: 64px;
  a {
    color: $color-black;
    &.read-more {
      color: $color-primary;
    }
  }
  
}
</style>
